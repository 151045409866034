var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c("TopBar", {
            ref: "topBar",
            attrs: { title: "Layers" },
            on: { "show-notifications": _vm.onShowNotifications }
          }),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showOfflinePane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showOfflinePane,
                callback: function($$v) {
                  _vm.showOfflinePane = $$v
                },
                expression: "showOfflinePane"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  style: { height: "100vh" }
                },
                [
                  _c(
                    "section",
                    {
                      staticClass: "py-0 my-0 d-flex justify-end",
                      style: { height: "56px" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { icon: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.showOfflinePane = false
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "section",
                    {
                      staticClass:
                        "d-flex justify-center align-center px-3 flex-grow-1"
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "gray" } },
                        [_vm._v(" " + _vm._s(_vm.mdiCloudOffOutline) + " ")]
                      ),
                      _vm._v(" Offline. GIS attributes are unavailable. ")
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": 10,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _vm.showNotificationPane
                ? _c("NotificationsPane", {
                    on: {
                      "close-notification-pane": function($event) {
                        _vm.showNotificationPane = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              staticClass: "elevation-3",
              staticStyle: { "z-index": "100" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showFeatureLayerGisInfoPanel,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showFeatureLayerGisInfoPanel,
                callback: function($$v) {
                  _vm.showFeatureLayerGisInfoPanel = $$v
                },
                expression: "showFeatureLayerGisInfoPanel"
              }
            },
            [
              _vm.showFeatureLayerGisInfoPanel
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { height: "100%" },
                      attrs: { id: "infoPanel" }
                    },
                    [
                      _c("FeatureItemGisInfoTopCard", {
                        attrs: {
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          objectId: _vm.selectedFeatureLayerObjectId,
                          globalId: _vm.gisInfoId,
                          attributes: _vm.attributes,
                          sharedSite: _vm.sharedSite
                        },
                        on: {
                          "gis-info-panel-close": function($event) {
                            _vm.showFeatureLayerGisInfoPanel = false
                            _vm.highlightedRowIds = []
                          },
                          "open-expand-panel-dialog": function($event) {
                            _vm.showExpansionPanelDialog = true
                          },
                          "edited-site": _vm.createSharedSiteIfNotExist
                        }
                      }),
                      _c("FeatureItemButtonBar", {
                        attrs: {
                          globalId: _vm.selectedFeatureLayerGlobalId,
                          objectId: _vm.selectedFeatureLayerObjectId,
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          taskCount: _vm.taskCount
                        },
                        on: {
                          "gis-info-button-clicked": function($event) {
                            _vm.gisInfoTab = $event
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "background" },
                        [
                          _vm.showFeatureLayerGisInfoPanel
                            ? _c("TicketFormDefDropdown", {
                                attrs: {
                                  inRightPane: "",
                                  globalId: _vm.selectedFeatureLayerGlobalId,
                                  selectedMapServiceId:
                                    _vm.selectedMapServiceId,
                                  objectId: _vm.selectedFeatureLayerObjectId,
                                  selectedGisInfoObj: _vm.selectedGisInfoObj
                                },
                                on: {
                                  "ticket-edit-form-submitted": function(
                                    $event
                                  ) {
                                    _vm.onTicketEditFormSubmitted()
                                    _vm.showUtiliBotDialogOnFormSubmitted()
                                  },
                                  "show-edit-dialog": function($event) {
                                    _vm.openGisInfoPanels = [1]
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.showFeatureLayerGisInfoPanel
                        ? _c("SharePublicSite", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedLayer.site_enabled,
                                expression: "selectedLayer.site_enabled"
                              }
                            ],
                            attrs: {
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.selectedFeatureLayerObjectId,
                              attributes: _vm.featureItemGisInfoAttributes,
                              sharedSite: _vm.sharedSite,
                              globalId: _vm.selectedFeatureLayerGlobalId
                            },
                            on: {
                              "public-link-created":
                                _vm.createSharedSiteIfNotExist,
                              "removed-sharing": _vm.createSharedSiteIfNotExist,
                              "site-name-saved": _vm.createSharedSiteIfNotExist
                            }
                          })
                        : _vm._e(),
                      _vm.selectedLayer.site_enabled
                        ? _c("v-divider")
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "flex-grow-1 overflow-y-auto background"
                        },
                        [
                          _c("SortedGisInfo", {
                            attrs: {
                              globalId: _vm.selectedFeatureLayerGlobalId,
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.selectedFeatureLayerObjectId
                            },
                            on: {
                              "esri-field-saved": function($event) {
                                _vm.onLayerIdChange()
                                _vm.reloadRowClicked()
                              },
                              "utilisync-field-saved": function($event) {
                                _vm.onLayerIdChange()
                                _vm.reloadRowClicked()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticClass: "elevation-3",
              staticStyle: { "z-index": "100" },
              attrs: {
                app: "",
                "hide-overlay": "",
                width: "375px",
                permanent:
                  _vm.showGisInfoPanel && _vm.$vuetify.breakpoint.smAndUp,
                right: _vm.$vuetify.breakpoint.smAndUp,
                bottom: "",
                stateless: _vm.$vuetify.breakpoint.smAndUp
              },
              model: {
                value: _vm.showGisInfoPanel,
                callback: function($$v) {
                  _vm.showGisInfoPanel = $$v
                },
                expression: "showGisInfoPanel"
              }
            },
            [
              _vm.showGisInfoPanel
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { height: "100%" },
                      attrs: { id: "infoPanel" }
                    },
                    [
                      _c("GisInfoTopCard", {
                        attrs: {
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          globalId: _vm.gisInfoId,
                          objectId: _vm.featureId,
                          sharedSite: _vm.sharedSite
                        },
                        on: {
                          "gis-info-panel-close": _vm.onGisInfoPanelClose,
                          "open-expand-panel-dialog": function($event) {
                            _vm.showExpansionPanelDialog = true
                          },
                          "edited-site": _vm.createSharedSiteIfNotExist
                        }
                      }),
                      !["S"].includes(_vm.selectedLayerType)
                        ? _c("GisInfoButtonBar", {
                            attrs: {
                              globalId: _vm.gisInfoId,
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              taskCount: _vm.taskCount
                            },
                            on: {
                              "gis-info-button-clicked": function($event) {
                                _vm.gisInfoTab = $event
                              }
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "background" },
                        [
                          _vm.showGisInfoPanel
                            ? _c("TicketFormDefDropdown", {
                                attrs: {
                                  inRightPane: "",
                                  globalId: _vm.gisInfoId,
                                  selectedMapServiceId:
                                    _vm.selectedMapServiceId,
                                  objectId: _vm.featureId,
                                  selectedGisInfoObj: _vm.selectedGisInfoObj
                                },
                                on: {
                                  "ticket-edit-form-submitted": function(
                                    $event
                                  ) {
                                    _vm.onTicketEditFormSubmitted()
                                    _vm.showUtiliBotDialogOnFormSubmitted()
                                  },
                                  "show-edit-dialog": function($event) {
                                    _vm.openGisInfoPanels = [1]
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.showGisInfoPanel
                        ? _c("SharePublicSite", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedLayer.site_enabled,
                                expression: "selectedLayer.site_enabled"
                              }
                            ],
                            attrs: {
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.featureId,
                              attributes: _vm.rawGisInfoAttributes,
                              sharedSite: _vm.sharedSite,
                              globalId: _vm.gisInfoId
                            },
                            on: {
                              "public-link-created":
                                _vm.createSharedSiteIfNotExist,
                              "removed-sharing": _vm.createSharedSiteIfNotExist,
                              "site-name-saved": _vm.createSharedSiteIfNotExist
                            }
                          })
                        : _vm._e(),
                      _vm.selectedLayer.site_enabled
                        ? _c("v-divider")
                        : _vm._e(),
                      ["U"].includes(_vm.selectedLayerType)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-grow-1 overflow-y-auto background"
                            },
                            [
                              _c("SortedGisInfo", {
                                attrs: {
                                  inRightPane: "",
                                  globalId: _vm.gisInfoId,
                                  selectedMapServiceId:
                                    _vm.selectedMapServiceId,
                                  objectId: _vm.featureId
                                },
                                on: {
                                  "esri-field-saved": function($event) {
                                    _vm.onLayerIdChange()
                                    _vm.reloadRowClicked()
                                  },
                                  "utilisync-field-saved": function($event) {
                                    _vm.onLayerIdChange()
                                    _vm.reloadRowClicked()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showTasksTab,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showTasksTab,
                callback: function($$v) {
                  _vm.showTasksTab = $$v
                },
                expression: "showTasksTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "white--text" }, [
                                    _vm._v("Tasks")
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTasksTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showTasksTab
                    ? [
                        ["U"].includes(_vm.selectedLayerType)
                          ? _c("TasksTab", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.selectedGisInfoId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.selectedFeatureId
                              },
                              on: {
                                "update-task-count": function($event) {
                                  _vm.taskCount = $event
                                }
                              }
                            })
                          : ["F"].includes(_vm.selectedLayerType)
                          ? _c("TasksTab", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.selectedFeatureLayerGlobalId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.selectedFeatureLayerObjectId
                              },
                              on: {
                                "update-task-count": function($event) {
                                  _vm.taskCount = $event
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showTicketLogTab,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showTicketLogTab,
                callback: function($$v) {
                  _vm.showTicketLogTab = $$v
                },
                expression: "showTicketLogTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "white--text" }, [
                                    _vm._v("Log")
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTicketLogTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showTicketLogTab
                    ? [
                        ["U"].includes(_vm.selectedLayerType)
                          ? _c("Log", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.selectedGisInfoId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.selectedFeatureId
                              }
                            })
                          : ["F"].includes(_vm.selectedLayerType)
                          ? _c("Log", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.selectedFeatureLayerGlobalId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.selectedFeatureLayerObjectId
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showDocsTab,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showDocsTab,
                callback: function($$v) {
                  _vm.showDocsTab = $$v
                },
                expression: "showDocsTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c("div", { staticClass: "white--text" }, [
                                    _vm._v("Documents")
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showDocsTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showDocsTab
                    ? [
                        ["U"].includes(_vm.selectedLayerType)
                          ? _c("DocsTab", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.selectedGisInfoId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.selectedFeatureId
                              }
                            })
                          : ["F"].includes(_vm.selectedLayerType)
                          ? _c("DocsTab", {
                              staticClass: "overflow-y-auto",
                              attrs: {
                                globalId: _vm.selectedFeatureLayerGlobalId,
                                selectedMapServiceId: _vm.selectedMapServiceId,
                                objectId: _vm.selectedFeatureLayerObjectId
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          ),
          _vm.showGisDataPointFilterDialog
            ? _c("GisDataPointFilterDialog", {
                attrs: {
                  showGisDataPointFilterDialog:
                    _vm.showGisDataPointFilterDialog,
                  gisDataPoints: _vm.gisDataPoints,
                  savedFilterChoices: _vm.filterChoices,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  featureItemFields: _vm.featureItemFields
                },
                on: {
                  "gis-data-point-filter-close": function($event) {
                    _vm.showGisDataPointFilterDialog = false
                  },
                  "update-filter": _vm.onUpdateFilter
                }
              })
            : _vm._e(),
          _vm.showAfterFormSubmissionUtilibotDialog
            ? _c("UtilibotDialog", {
                attrs: {
                  showUtilibotDialog: _vm.showAfterFormSubmissionUtilibotDialog,
                  formResultId: _vm.formSubmitted.formResultId,
                  openedAfterFormSubmission: ""
                },
                on: {
                  "utilibot-dialog-close": function($event) {
                    _vm.showAfterFormSubmissionUtilibotDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("ListViewMapView", {
            staticStyle: { display: "none" },
            attrs: { selectedLayers: _vm.selectedLayers },
            on: { "map-created": _vm.onMapCreated }
          }),
          _vm.map
            ? [
                _c(
                  "section",
                  {
                    ref: "filterDisplay",
                    staticClass: "d-flex justify-space-between flex-wrap"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center px-5 pt-3" },
                      [
                        _c("v-autocomplete", {
                          staticClass: "autocomplete",
                          attrs: {
                            items: _vm.layerChoices,
                            "item-text": "label",
                            "item-value": "value",
                            label: "Layer",
                            "menu-props": "auto"
                          },
                          on: { change: _vm.onLayerIdChange },
                          model: {
                            value: _vm.selectedMapServiceId,
                            callback: function($$v) {
                              _vm.selectedMapServiceId = $$v
                            },
                            expression: "selectedMapServiceId"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color: "#3F51B5",
                              disabled: !_vm.selectedMapServiceId,
                              id: "filterButton"
                            },
                            on: {
                              click: function($event) {
                                _vm.showGisDataPointFilterDialog = true
                              }
                            }
                          },
                          [
                            _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                            _vm._v(" Filter ")
                          ],
                          1
                        ),
                        _c("FilterChoicesDisplay", {
                          attrs: { filterChoices: _vm.filterChoices },
                          on: { "remove-filter": _vm.removeFilter }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "align-center px-5 mt-4" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-1 mx-1",
                            attrs: {
                              text: "",
                              color: "#3F51B5",
                              disabled: !_vm.isOnline || _vm.isDownloading
                            },
                            on: { click: _vm.reload }
                          },
                          [
                            _vm.isDownloading
                              ? _c("v-progress-circular", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    color: "#3F51B5",
                                    size: 15,
                                    indeterminate: ""
                                  }
                                })
                              : _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "#3F51B5" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.mdiRefresh) + " ")]
                                ),
                            _vm._v(" Reload ")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "text-caption" }, [
                          _vm._v(
                            " Updated: " +
                              _vm._s(
                                _vm._f("formatVariableDate")(
                                  _vm.lastUpdated,
                                  "hh:mm A"
                                )
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _vm.selectedLayerType === "U"
                  ? _c(
                      "div",
                      [
                        _vm.showLoader
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "#3F51B5" }
                                })
                              ],
                              1
                            )
                          : _c("v-data-table", {
                              staticClass: "pa-0 ma-0 cursor-pointer",
                              attrs: {
                                headers: _vm.filteredHeaders,
                                items: _vm.filteredMappedGisDataPoints,
                                "item-key": "featureId",
                                "hide-default-footer": "",
                                "footer-props": {
                                  "items-per-page-options": [100]
                                },
                                "items-per-page": 100,
                                height: _vm.tableHeight,
                                "fixed-header": "",
                                "item-class": _vm.getRowClass
                              },
                              on: { "click:row": _vm.onRowClick },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header.menu",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color: "#3F51B5"
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.mdiPlusCircle
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1716732293
                                            )
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-text",
                                                  _vm._l(
                                                    _vm.nonEmptyHeaders,
                                                    function(h) {
                                                      return _c("v-checkbox", {
                                                        key: h.value,
                                                        staticClass:
                                                          "py-0 my-0",
                                                        attrs: {
                                                          label: h.text
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.headersEnabled[
                                                              h.value
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.headersEnabled,
                                                              h.value,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "headersEnabled[h.value]"
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  _vm._l(_vm.filteredHeaders, function(h) {
                                    return {
                                      key: "item." + h.value,
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm.isRichTextField(item[h.value])
                                            ? _c("div", {
                                                key: h.value,
                                                staticClass:
                                                  "d-flex align-center",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item[h.value]
                                                  )
                                                }
                                              })
                                            : _vm.getEsriFieldType(h.value) ===
                                                "esriFieldTypeDate" &&
                                              item[h.value]
                                            ? _c("div", { key: h.value }, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("formatDate")(
                                                        new Date(item[h.value])
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c(
                                                "div",
                                                {
                                                  key: h.value,
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item[h.value]) +
                                                      " "
                                                  )
                                                ]
                                              )
                                        ]
                                      }
                                    }
                                  }),
                                  {
                                    key: "footer",
                                    fn: function(ref) {
                                      var props = ref.props
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          {
                                            ref: "bottomBar",
                                            staticClass: "d-flex justify-end"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "v-data-footer",
                                                  _vm._g(
                                                    {
                                                      staticStyle: {
                                                        border: "none"
                                                      },
                                                      attrs: {
                                                        "items-per-page-options": [
                                                          100
                                                        ],
                                                        options: props.options,
                                                        pagination:
                                                          props.pagination
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                      ],
                      1
                    )
                  : _vm.selectedLayerType === "F"
                  ? _c(
                      "div",
                      [
                        _vm.showLoader
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "#3F51B5" }
                                })
                              ],
                              1
                            )
                          : _c("v-data-table", {
                              staticClass: "pa-0 ma-0 cursor-pointer",
                              attrs: {
                                headers: _vm.filteredFeatureTableHeaders,
                                items: _vm.filteredSelectedFeatureLayerFeatures,
                                "item-key": "OBJECTID",
                                "hide-default-footer": "",
                                "footer-props": {
                                  "items-per-page-options": [100]
                                },
                                "items-per-page": 100,
                                height: _vm.tableHeight,
                                "fixed-header": "",
                                "item-class": _vm.getRowClass
                              },
                              on: { "click:row": _vm.onRowClick },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header.menu",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                color: "#3F51B5"
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.mdiPlusCircle
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1716732293
                                            )
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-text",
                                                  _vm._l(
                                                    _vm.nonEmptyFeatureLayerTableHeaders,
                                                    function(h) {
                                                      return _c("v-checkbox", {
                                                        key: h.value,
                                                        staticClass:
                                                          "py-0 my-0",
                                                        attrs: {
                                                          label: h.text
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .featureLayerTableHeadersEnabled[
                                                              h.value
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.featureLayerTableHeadersEnabled,
                                                              h.value,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "featureLayerTableHeadersEnabled[h.value]"
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  _vm._l(
                                    _vm.filteredFeatureTableHeaders,
                                    function(h) {
                                      return {
                                        key: "item." + h.value,
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _vm.isRichTextField(item[h.value])
                                              ? _c("div", {
                                                  key: h.value,
                                                  staticClass:
                                                    "d-flex align-center",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item[h.value]
                                                    )
                                                  }
                                                })
                                              : _vm.getEsriFieldType(
                                                  h.value
                                                ) === "esriFieldTypeDate" &&
                                                item[h.value] !== null
                                              ? _c("div", { key: h.value }, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          new Date(
                                                            item[h.value]
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _c(
                                                  "div",
                                                  {
                                                    key: h.value,
                                                    staticClass:
                                                      "d-flex align-center"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item[h.value]) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                          ]
                                        }
                                      }
                                    }
                                  ),
                                  {
                                    key: "footer",
                                    fn: function(ref) {
                                      var props = ref.props
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          {
                                            ref: "bottomBar",
                                            staticClass:
                                              "d-flex justify-end py-0 my-0"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex py-0 my-0"
                                              },
                                              [
                                                _c(
                                                  "v-data-footer",
                                                  _vm._g(
                                                    {
                                                      staticStyle: {
                                                        border: "none"
                                                      },
                                                      attrs: {
                                                        "items-per-page-options": [
                                                          100
                                                        ],
                                                        options: props.options,
                                                        pagination:
                                                          props.pagination
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _c(
                "div",
                {
                  staticClass: "d-flex justify-center",
                  staticStyle: { width: "100vw" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "#3F51B5" }
                  })
                ],
                1
              ),
          _vm.showExpansionPanelDialog
            ? [
                ["U"].includes(_vm.selectedLayerType)
                  ? _c("ExpandInfoPanelDialog", {
                      attrs: {
                        showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                        globalId: _vm.selectedGisInfoId,
                        selectedMapServiceId: _vm.selectedMapServiceId,
                        objectId: _vm.selectedFeatureId,
                        selectedGisInfoObj: _vm.selectedGisInfoObj
                      },
                      on: {
                        "expand-info-panel-close": function($event) {
                          _vm.showExpansionPanelDialog = false
                          _vm.onLayerIdChange()
                          _vm.loadData(_vm.selectedRowValue)
                        }
                      }
                    })
                  : ["F"].includes(_vm.selectedLayerType)
                  ? _c("ExpandInfoPanelDialog", {
                      attrs: {
                        showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                        globalId: _vm.selectedFeatureLayerGlobalId,
                        selectedMapServiceId: _vm.selectedMapServiceId,
                        objectId: _vm.selectedFeatureLayerObjectId,
                        selectedGisInfoObj: _vm.selectedGisInfoObj
                      },
                      on: {
                        "expand-info-panel-close": function($event) {
                          _vm.showExpansionPanelDialog = false
                          _vm.onLayerIdChange()
                          _vm.loadData(_vm.selectedRowValue)
                        }
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          { attrs: { text: "" }, on: { click: _vm.viewForm } },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" View Form ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.showFormSubmittedSnackbar,
                callback: function($$v) {
                  _vm.showFormSubmittedSnackbar = $$v
                },
                expression: "showFormSubmittedSnackbar"
              }
            },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
              ]),
              _vm._v(" Success! The form was submitted. ")
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                persistent: "",
                fullscreen: _vm.isFullScreen
              },
              model: {
                value: _vm.showEditFormDialog,
                callback: function($$v) {
                  _vm.showEditFormDialog = $$v
                },
                expression: "showEditFormDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.showEditFormDialog
                    ? _c("DynamicForm", {
                        attrs: {
                          formDefinition: _vm.dynamicFormProps.formDefinition,
                          existingFormResultIdMap:
                            _vm.dynamicFormProps.existingFormResultIdMap,
                          selectedPdfFileUrl:
                            _vm.dynamicFormProps.selectedPdfFileUrl,
                          canEdit: _vm.dynamicFormProps.canEdit,
                          alreadySubmittedFinalOnline:
                            _vm.dynamicFormProps.alreadySubmittedFinalOnline,
                          globalId: _vm.dynamicFormProps.globalId,
                          objectId: _vm.dynamicFormProps.objectId,
                          selectedMapServiceId:
                            _vm.dynamicFormProps.selectedMapServiceId,
                          taskId: _vm.dynamicFormProps.taskId
                        },
                        on: {
                          "ticket-edit-form-close-button-click": function(
                            $event
                          ) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-close": function($event) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-submitted": function($event) {
                            _vm.showEditFormDialog = false
                            _vm.showUtiliBotDialogOnFormSubmitted()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.showAuthExpiredDialog
            ? _c("AuthExpiredDialog", {
                attrs: { showAuthExpiredDialog: _vm.showAuthExpiredDialog },
                on: {
                  "portal-login-success": function($event) {
                    _vm.showAuthExpiredDialog = false
                  },
                  "auth-expired-dialog-close": function($event) {
                    _vm.showAuthExpiredDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }