<template>
  <v-app-bar class="white--text py-0 my-0 top-bar" height="56px">
    <v-app-bar-nav-icon>
      <v-btn text color="white" @click="toggleMenu(!isMenuOpen)">
        <v-badge
          color="orange"
          :content="numUnsubmittedTickets"
          overlap
          :value="numUnsubmittedTickets > 0"
        >
          <v-icon>{{ mdiMenu }}</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar-nav-icon>

    <v-toolbar-title
      class="d-flex justify-space-between align-center px-3 my-0 my-0"
      style="width: 100%; height: 100%"
    >
      <span>{{ title }}</span>

      <v-toolbar-items class="d-flex align-center">
        <v-badge dot color="orange" overlap :value="unreadNotifications">
          <v-icon color="white" @click="$emit('show-notifications')">
            {{ mdiBell }}
          </v-icon>
        </v-badge>
      </v-toolbar-items>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mdiMenu, mdiBell } from "@mdi/js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "TopBar",
  props: {
    title: String,
  },
  computed: {
    ...mapGetters([
      "isMenuOpen",
      "numUnsubmittedTickets",
      "unreadNotifications",
    ]),
    isVersionLog() {
      return this.$route.name === "VersionLog";
    },
  },
  data() {
    return {
      mdiMenu,
      mdiBell,
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    toggleMenu(showMenu) {
      this.setMenuState(showMenu);
    },
  },
};
</script>
