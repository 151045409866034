<template>
  <div id="viewDiv"></div>
</template>

<script>
import { loadModules } from "esri-loader";
import mapLayerMixin from "@/mixins/mapLayerMixin";

export default {
  name: "ListViewMapView",
  data() {
    return {
      view: undefined,
      map: undefined,
    };
  },
  props: {
    selectedLayers: Array,
  },
  mixins: [mapLayerMixin],
  beforeDestroy() {
    if (this.view) {
      this.view.destroy();
    }
  },
  methods: {
    async renderMap() {
      const [
        Map,
        MapView,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        MapImageLayer,
        esriConfig,
      ] = await loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/SimpleRenderer",
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/symbols/SimpleFillSymbol",
        "esri/rest/support/ImageParameters",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/geometry/Point",
        "esri/geometry/SpatialReference",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/PopupTemplate",
        "esri/layers/MapImageLayer",
        "esri/config",
      ]);
      const map = new Map();
      this.map = map;
      this.view = new MapView({
        container: "viewDiv",
        map,
        popup: {
          autoOpenEnabled: false,
        },
      });

      const { selectedLayers: layers } = this;
      const defaultLayersVisibility = layers.map((l) => {
        const { map_service_id: mapServiceId, is_visible: isVisible } = l;
        return {
          mapServiceId,
          isVisible,
        };
      });
      this.addLayers({
        map,
        layers,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        MapImageLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        esriConfig,
        defaultLayersVisibility,
      });
      const { view } = this;
      this.$emit("map-created", { map, view });
    },
    async reloadMap() {
      if (!this.map) {
        this.renderMap();
        return;
      }
      const [
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        MapImageLayer,
        esriConfig,
      ] = await loadModules([
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/SimpleRenderer",
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/symbols/SimpleFillSymbol",
        "esri/rest/support/ImageParameters",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/geometry/Point",
        "esri/geometry/SpatialReference",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/PopupTemplate",
        "esri/layers/MapImageLayer",
        "esri/config",
      ]);
      const { map, selectedLayers: layers } = this;
      map.removeAll();
      const defaultLayersVisibility = layers.map((l) => {
        const { map_service_id: mapServiceId, is_visible: isVisible } = l;
        return {
          mapServiceId,
          isVisible,
        };
      });
      this.addLayers({
        map,
        layers,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        MapImageLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        esriConfig,
        config: {
          mapConfig: {
            map_services: [layers],
          },
          loadedLayers: [],
          layers,
        },
        defaultLayersVisibility,
      });
      const { view } = this;
      this.$emit("map-created", { map, view });
    },
  },
  watch: {
    selectedLayers: {
      immediate: true,
      deep: true,
      async handler(val) {
        await this.reloadMap(val);
      },
    },
    layerChoices: {
      deep: true,
      handler() {
        this.selectedMapServiceId = localStorage.getItem(
          "selected-map-service-id"
        );
      },
    },
  },
};
</script>

<style scoped>
#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>

<style>
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}

#viewDiv {
  outline: none;
}
</style>
