var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { staticClass: "white--text py-0 my-0 top-bar", attrs: { height: "56px" } },
    [
      _c(
        "v-app-bar-nav-icon",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.toggleMenu(!_vm.isMenuOpen)
                }
              }
            },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "orange",
                    content: _vm.numUnsubmittedTickets,
                    overlap: "",
                    value: _vm.numUnsubmittedTickets > 0
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMenu))])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-toolbar-title",
        {
          staticClass:
            "d-flex justify-space-between align-center px-3 my-0 my-0",
          staticStyle: { width: "100%", height: "100%" }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "v-toolbar-items",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    dot: "",
                    color: "orange",
                    overlap: "",
                    value: _vm.unreadNotifications
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("show-notifications")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.mdiBell) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }