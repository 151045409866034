<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0">
      <TopBar
        title="Layers"
        @show-notifications="onShowNotifications"
        ref="topBar"
      />

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showOfflinePane"
        right
        :permanent="showOfflinePane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1,
          'background-color': '#fafafa',
        }"
      >
        <div :style="{ height: '100vh' }" class="d-flex flex-column">
          <section
            class="py-0 my-0 d-flex justify-end"
            :style="{ height: '56px' }"
          >
            <v-btn
              icon
              text
              @click="showOfflinePane = false"
              style="font-size: 12px"
            >
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </section>

          <section class="d-flex justify-center align-center px-3 flex-grow-1">
            <v-icon class="mr-1" color="gray">
              {{ mdiCloudOffOutline }}
            </v-icon>
            Offline. GIS attributes are unavailable.
          </section>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': 10,
          'background-color': '#fafafa',
        }"
      >
        <NotificationsPane
          v-if="showNotificationPane"
          @close-notification-pane="showNotificationPane = false"
        />
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showFeatureLayerGisInfoPanel"
        right
        :permanent="showFeatureLayerGisInfoPanel"
        style="z-index: 100"
        hide-overlay
        width="375px"
        stateless
        class="elevation-3"
      >
        <div
          class="d-flex flex-column"
          style="height: 100%"
          v-if="showFeatureLayerGisInfoPanel"
          id="infoPanel"
        >
          <FeatureItemGisInfoTopCard
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="selectedFeatureLayerObjectId"
            :globalId="gisInfoId"
            :attributes="attributes"
            :sharedSite="sharedSite"
            @gis-info-panel-close="
              showFeatureLayerGisInfoPanel = false;
              highlightedRowIds = [];
            "
            @open-expand-panel-dialog="showExpansionPanelDialog = true"
            @edited-site="createSharedSiteIfNotExist"
          />

          <FeatureItemButtonBar
            @gis-info-button-clicked="gisInfoTab = $event"
            :globalId="selectedFeatureLayerGlobalId"
            :objectId="selectedFeatureLayerObjectId"
            :selectedMapServiceId="selectedMapServiceId"
            :taskCount="taskCount"
          />

          <div class="background">
            <TicketFormDefDropdown
              v-if="showFeatureLayerGisInfoPanel"
              inRightPane
              :globalId="selectedFeatureLayerGlobalId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureLayerObjectId"
              :selectedGisInfoObj="selectedGisInfoObj"
              @ticket-edit-form-submitted="
                onTicketEditFormSubmitted();
                showUtiliBotDialogOnFormSubmitted();
              "
              @show-edit-dialog="openGisInfoPanels = [1]"
            />
          </div>

          <v-divider></v-divider>

          <SharePublicSite
            v-if="showFeatureLayerGisInfoPanel"
            v-show="selectedLayer.site_enabled"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="selectedFeatureLayerObjectId"
            :attributes="featureItemGisInfoAttributes"
            :sharedSite="sharedSite"
            :globalId="selectedFeatureLayerGlobalId"
            @public-link-created="createSharedSiteIfNotExist"
            @removed-sharing="createSharedSiteIfNotExist"
            @site-name-saved="createSharedSiteIfNotExist"
          />

          <v-divider v-if="selectedLayer.site_enabled"></v-divider>

          <div class="flex-grow-1 overflow-y-auto background">
            <SortedGisInfo
              :globalId="selectedFeatureLayerGlobalId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureLayerObjectId"
              @esri-field-saved="
                onLayerIdChange();
                reloadRowClicked();
              "
              @utilisync-field-saved="
                onLayerIdChange();
                reloadRowClicked();
              "
            />
          </div>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showGisInfoPanel"
        style="z-index: 100"
        hide-overlay
        width="375px"
        :permanent="showGisInfoPanel && $vuetify.breakpoint.smAndUp"
        :right="$vuetify.breakpoint.smAndUp"
        bottom
        :stateless="$vuetify.breakpoint.smAndUp"
        class="elevation-3"
      >
        <div
          class="d-flex flex-column"
          style="height: 100%"
          v-if="showGisInfoPanel"
          id="infoPanel"
        >
          <GisInfoTopCard
            :selectedMapServiceId="selectedMapServiceId"
            :globalId="gisInfoId"
            :objectId="featureId"
            :sharedSite="sharedSite"
            @gis-info-panel-close="onGisInfoPanelClose"
            @open-expand-panel-dialog="showExpansionPanelDialog = true"
            @edited-site="createSharedSiteIfNotExist"
          />

          <GisInfoButtonBar
            @gis-info-button-clicked="gisInfoTab = $event"
            v-if="!['S'].includes(selectedLayerType)"
            :globalId="gisInfoId"
            :selectedMapServiceId="selectedMapServiceId"
            :taskCount="taskCount"
          />

          <div class="background">
            <TicketFormDefDropdown
              v-if="showGisInfoPanel"
              inRightPane
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="featureId"
              :selectedGisInfoObj="selectedGisInfoObj"
              @ticket-edit-form-submitted="
                onTicketEditFormSubmitted();
                showUtiliBotDialogOnFormSubmitted();
              "
              @show-edit-dialog="openGisInfoPanels = [1]"
            />
          </div>

          <v-divider></v-divider>

          <SharePublicSite
            v-if="showGisInfoPanel"
            v-show="selectedLayer.site_enabled"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="featureId"
            :attributes="rawGisInfoAttributes"
            :sharedSite="sharedSite"
            :globalId="gisInfoId"
            @public-link-created="createSharedSiteIfNotExist"
            @removed-sharing="createSharedSiteIfNotExist"
            @site-name-saved="createSharedSiteIfNotExist"
          />

          <v-divider v-if="selectedLayer.site_enabled"></v-divider>

          <div
            v-if="['U'].includes(selectedLayerType)"
            class="flex-grow-1 overflow-y-auto background"
          >
            <SortedGisInfo
              inRightPane
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="featureId"
              @esri-field-saved="
                onLayerIdChange();
                reloadRowClicked();
              "
              @utilisync-field-saved="
                onLayerIdChange();
                reloadRowClicked();
              "
            />
          </div>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showTasksTab"
        right
        :permanent="showTasksTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0" two-line>
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text">Tasks</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showTasksTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-if="showTasksTab">
            <TasksTab
              class="overflow-y-auto"
              v-if="['U'].includes(selectedLayerType)"
              :globalId="selectedGisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureId"
              @update-task-count="taskCount = $event"
            />
            <TasksTab
              class="overflow-y-auto"
              v-else-if="['F'].includes(selectedLayerType)"
              :globalId="selectedFeatureLayerGlobalId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureLayerObjectId"
              @update-task-count="taskCount = $event"
            />
          </template>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showTicketLogTab"
        right
        :permanent="showTicketLogTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0" two-line>
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text">Log</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showTicketLogTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-if="showTicketLogTab">
            <Log
              class="overflow-y-auto"
              v-if="['U'].includes(selectedLayerType)"
              :globalId="selectedGisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureId"
            />
            <Log
              class="overflow-y-auto"
              v-else-if="['F'].includes(selectedLayerType)"
              :globalId="selectedFeatureLayerGlobalId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureLayerObjectId"
            />
          </template>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showDocsTab"
        right
        :permanent="showDocsTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0" two-line>
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text">Documents</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showDocsTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-if="showDocsTab">
            <DocsTab
              class="overflow-y-auto"
              v-if="['U'].includes(selectedLayerType)"
              :globalId="selectedGisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureId"
            />
            <DocsTab
              class="overflow-y-auto"
              v-else-if="['F'].includes(selectedLayerType)"
              :globalId="selectedFeatureLayerGlobalId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="selectedFeatureLayerObjectId"
            />
          </template>
        </div>
      </v-navigation-drawer>

      <GisDataPointFilterDialog
        v-if="showGisDataPointFilterDialog"
        :showGisDataPointFilterDialog="showGisDataPointFilterDialog"
        :gisDataPoints="gisDataPoints"
        :savedFilterChoices="filterChoices"
        :selectedMapServiceId="selectedMapServiceId"
        :featureItemFields="featureItemFields"
        @gis-data-point-filter-close="showGisDataPointFilterDialog = false"
        @update-filter="onUpdateFilter"
      />

      <UtilibotDialog
        v-if="showAfterFormSubmissionUtilibotDialog"
        :showUtilibotDialog="showAfterFormSubmissionUtilibotDialog"
        :formResultId="formSubmitted.formResultId"
        openedAfterFormSubmission
        @utilibot-dialog-close="showAfterFormSubmissionUtilibotDialog = false"
      />

      <ListViewMapView
        style="display: none"
        :selectedLayers="selectedLayers"
        @map-created="onMapCreated"
      />

      <template v-if="map">
        <section
          class="d-flex justify-space-between flex-wrap"
          ref="filterDisplay"
        >
          <div class="d-flex align-center px-5 pt-3">
            <v-autocomplete
              :items="layerChoices"
              v-model="selectedMapServiceId"
              item-text="label"
              item-value="value"
              @change="onLayerIdChange"
              label="Layer"
              menu-props="auto"
              class="autocomplete"
            >
            </v-autocomplete>

            <v-btn
              text
              color="#3F51B5"
              @click="showGisDataPointFilterDialog = true"
              :disabled="!selectedMapServiceId"
              id="filterButton"
            >
              <v-icon> {{ mdiFilter }}</v-icon>
              Filter
            </v-btn>

            <FilterChoicesDisplay
              :filterChoices="filterChoices"
              @remove-filter="removeFilter"
            />
          </div>

          <div class="align-center px-5 mt-4">
            <v-btn
              text
              color="#3F51B5"
              @click="reload"
              :disabled="!isOnline || isDownloading"
              class="px-1 mx-1"
            >
              <v-progress-circular
                color="#3F51B5"
                v-if="isDownloading"
                :size="15"
                indeterminate
                class="mr-1"
              ></v-progress-circular>
              <v-icon color="#3F51B5" class="mr-1" v-else>
                {{ mdiRefresh }}
              </v-icon>
              Reload
            </v-btn>
            <div class="text-caption">
              Updated: {{ lastUpdated | formatVariableDate("hh:mm A") }}
            </div>
          </div>
        </section>

        <div v-if="selectedLayerType === 'U'">
          <div class="d-flex justify-center" v-if="showLoader">
            <v-progress-circular indeterminate color="#3F51B5">
            </v-progress-circular>
          </div>
          <v-data-table
            v-else
            :headers="filteredHeaders"
            :items="filteredMappedGisDataPoints"
            item-key="featureId"
            class="pa-0 ma-0 cursor-pointer"
            @click:row="onRowClick"
            hide-default-footer
            :footer-props="{
              'items-per-page-options': [100],
            }"
            :items-per-page="100"
            :height="tableHeight"
            fixed-header
            :item-class="getRowClass"
          >
            <template v-slot:[`header.menu`]>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text color="#3F51B5">
                    <v-icon dark>
                      {{ mdiPlusCircle }}
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-checkbox
                      v-for="h of nonEmptyHeaders"
                      :key="h.value"
                      @click.stop
                      v-model="headersEnabled[h.value]"
                      :label="h.text"
                      class="py-0 my-0"
                    >
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>

            <template
              v-for="h of filteredHeaders"
              #[`item.${h.value}`]="{ item }"
            >
              <div
                class="d-flex align-center"
                :key="h.value"
                v-if="isRichTextField(item[h.value])"
                v-html="item[h.value]"
              ></div>
              <div
                :key="h.value"
                v-else-if="
                  getEsriFieldType(h.value) === 'esriFieldTypeDate' &&
                  item[h.value]
                "
              >
                {{ new Date(item[h.value]) | formatDate }}
              </div>
              <div v-else :key="h.value" class="d-flex align-center">
                {{ item[h.value] }}
              </div>
            </template>

            <template v-slot:footer="{ props, on }">
              <div class="d-flex justify-end" ref="bottomBar">
                <div class="d-flex">
                  <v-data-footer
                    :items-per-page-options="[100]"
                    :options="props.options"
                    :pagination="props.pagination"
                    v-on="on"
                    style="border: none"
                  >
                  </v-data-footer>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-else-if="selectedLayerType === 'F'">
          <div class="d-flex justify-center" v-if="showLoader">
            <v-progress-circular indeterminate color="#3F51B5">
            </v-progress-circular>
          </div>
          <v-data-table
            v-else
            :headers="filteredFeatureTableHeaders"
            :items="filteredSelectedFeatureLayerFeatures"
            item-key="OBJECTID"
            class="pa-0 ma-0 cursor-pointer"
            @click:row="onRowClick"
            hide-default-footer
            :footer-props="{
              'items-per-page-options': [100],
            }"
            :items-per-page="100"
            :height="tableHeight"
            fixed-header
            :item-class="getRowClass"
          >
            <template v-slot:[`header.menu`]>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text color="#3F51B5">
                    <v-icon dark>
                      {{ mdiPlusCircle }}
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-checkbox
                      v-for="h of nonEmptyFeatureLayerTableHeaders"
                      :key="h.value"
                      @click.stop
                      v-model="featureLayerTableHeadersEnabled[h.value]"
                      :label="h.text"
                      class="py-0 my-0"
                    >
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>

            <template
              v-for="h of filteredFeatureTableHeaders"
              #[`item.${h.value}`]="{ item }"
            >
              <div
                class="d-flex align-center"
                :key="h.value"
                v-if="isRichTextField(item[h.value])"
                v-html="item[h.value]"
              ></div>
              <div
                :key="h.value"
                v-else-if="
                  getEsriFieldType(h.value) === 'esriFieldTypeDate' &&
                  item[h.value] !== null
                "
              >
                {{ new Date(item[h.value]) | formatDate }}
              </div>
              <div v-else :key="h.value" class="d-flex align-center">
                {{ item[h.value] }}
              </div>
            </template>

            <template v-slot:footer="{ props, on }">
              <div class="d-flex justify-end py-0 my-0" ref="bottomBar">
                <div class="d-flex py-0 my-0">
                  <v-data-footer
                    :items-per-page-options="[100]"
                    :options="props.options"
                    :pagination="props.pagination"
                    v-on="on"
                    style="border: none"
                  >
                  </v-data-footer>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </template>
      <div v-else class="d-flex justify-center" style="width: 100vw">
        <v-progress-circular indeterminate color="#3F51B5">
        </v-progress-circular>
      </div>

      <template v-if="showExpansionPanelDialog">
        <ExpandInfoPanelDialog
          v-if="['U'].includes(selectedLayerType)"
          :showExpansionPanelDialog="showExpansionPanelDialog"
          :globalId="selectedGisInfoId"
          :selectedMapServiceId="selectedMapServiceId"
          :objectId="selectedFeatureId"
          :selectedGisInfoObj="selectedGisInfoObj"
          @expand-info-panel-close="
            showExpansionPanelDialog = false;
            onLayerIdChange();
            loadData(selectedRowValue);
          "
        />
        <ExpandInfoPanelDialog
          v-else-if="['F'].includes(selectedLayerType)"
          :showExpansionPanelDialog="showExpansionPanelDialog"
          :globalId="selectedFeatureLayerGlobalId"
          :selectedMapServiceId="selectedMapServiceId"
          :objectId="selectedFeatureLayerObjectId"
          :selectedGisInfoObj="selectedGisInfoObj"
          @expand-info-panel-close="
            showExpansionPanelDialog = false;
            onLayerIdChange();
            loadData(selectedRowValue);
          "
        />
      </template>

      <v-snackbar v-model="showFormSubmittedSnackbar">
        <v-icon dark>
          {{ mdiInformation }}
        </v-icon>
        Success! The form was submitted.

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="viewForm"> View Form </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        v-model="showEditFormDialog"
        max-width="600px"
        persistent
        :fullscreen="isFullScreen"
      >
        <v-card>
          <DynamicForm
            :formDefinition="dynamicFormProps.formDefinition"
            :existingFormResultIdMap="dynamicFormProps.existingFormResultIdMap"
            :selectedPdfFileUrl="dynamicFormProps.selectedPdfFileUrl"
            :canEdit="dynamicFormProps.canEdit"
            :alreadySubmittedFinalOnline="
              dynamicFormProps.alreadySubmittedFinalOnline
            "
            :globalId="dynamicFormProps.globalId"
            :objectId="dynamicFormProps.objectId"
            :selectedMapServiceId="dynamicFormProps.selectedMapServiceId"
            :taskId="dynamicFormProps.taskId"
            @ticket-edit-form-close-button-click="showEditFormDialog = false"
            @ticket-edit-form-close="showEditFormDialog = false"
            @ticket-edit-form-submitted="
              showEditFormDialog = false;
              showUtiliBotDialogOnFormSubmitted();
            "
            v-if="showEditFormDialog"
          />
        </v-card>
      </v-dialog>

      <AuthExpiredDialog
        v-if="showAuthExpiredDialog"
        :showAuthExpiredDialog="showAuthExpiredDialog"
        @portal-login-success="showAuthExpiredDialog = false"
        @auth-expired-dialog-close="showAuthExpiredDialog = false"
      />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import { axiosWithNoAuth, axiosWithRegularAuth } from "@/plugins/axios";
import {
  mdiClose,
  mdiFilter,
  mdiPlusCircle,
  mdiRefresh,
  mdiInformation,
  mdiCloudOffOutline,
} from "@mdi/js";
import GisInfoTopCard from "@/components/mapView/GisInfoTopCard";
import GisInfoButtonBar from "@/components/mapView/GisInfoButtonBar.vue";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import Log from "@/components/tickets/Log.vue";
import GisDataPointFilterDialog from "@/components/list/GisDataPointFilterDialog.vue";
import ListViewMapView from "@/components/list/ListViewMapView";
import { loadModules } from "esri-loader";
import { getGisInfoObjectId, getGisInfoGlobalId } from "@/mixins/getId";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import ExpandInfoPanelDialog from "@/components/mapView/ExpandInfoPanelDialog.vue";
import FeatureItemGisInfoTopCard from "@/components/list/FeatureItemGisInfoTopCard";
import FeatureItemButtonBar from "@/components/list/FeatureItemButtonBar.vue";
import FilterChoicesDisplay from "@/components/list/FilterChoicesDisplay.vue";
import { cloneDeep } from "lodash";
import checkMatch from "@/mixins/checkMatch";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import signOutMixin from "@/mixins/signOutMixin";
import { db } from "@/mixins/utilisync-db";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import TABS from "@/constants/tabs";
import Dexie from "dexie";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import usetifulMixin from "@/mixins/usetifulMixin";
import SharePublicSite from "@/components/app/SharePublicSite";
import sharedSiteMixin from "@/mixins/sharedSiteMixin";
import { mapGetters, mapMutations } from "vuex";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import moment from "moment";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getActiveTaskCountMixin from "@/mixins/getActiveTaskCountMixin";
import getFormResultMixin from "@/mixins/getFormResultMixin";
import AuthExpiredDialog from "@/components/app/AuthExpiredDialog";
import validateEsriTokenMixin from "@/mixins/validateEsriTokenMixin";
import SortedGisInfo from "@/components/mapView/SortedGisInfo";
import notificationPaneMixin from "@/mixins/notificationPaneMixin";
import showUtiliBotDialogOnFormSubmittedMixin from "@/mixins/showUtiliBotDialogOnFormSubmittedMixin";
import UtilibotDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "List",
  components: {
    TopBar,
    NotificationsPane,
    GisInfoTopCard,
    GisInfoButtonBar,
    TicketFormDefDropdown,
    DocsTab,
    TasksTab,
    Log,
    GisDataPointFilterDialog,
    ListViewMapView,
    ExpandInfoPanelDialog,
    FeatureItemGisInfoTopCard,
    FeatureItemButtonBar,
    FilterChoicesDisplay,
    SharePublicSite,
    DynamicForm,
    AuthExpiredDialog,
    SortedGisInfo,
    UtilibotDialog,
  },
  mixins: [
    gisInfoMixin,
    downloadDataMixin,
    signOutMixin,
    bulkDownloadDataMixin,
    networkStatusMixin,
    usetifulMixin,
    sharedSiteMixin,
    fullScreenCheckMixin,
    getActiveTaskCountMixin,
    getFormResultMixin,
    validateEsriTokenMixin,
    notificationPaneMixin,
    showUtiliBotDialogOnFormSubmittedMixin,
  ],
  computed: {
    ...mapGetters(["formSubmitted", "dynamicFormProps"]),
    isDownloading() {
      const {
        downloadingMaps,
        downloadingMapServices,
        downloadingUsers,
        downloadingFormDefinitions,
        downloadingGisDataFieldOptions,
        downloadingMarkupSymbols,
        downloadingUserDataFields,
        downloadingUserDataValues,
        downloadingTasks,
        downloadingTaskTypes,
      } = this;
      return (
        downloadingMaps ||
        downloadingMapServices ||
        downloadingUsers ||
        downloadingFormDefinitions ||
        downloadingGisDataFieldOptions ||
        downloadingMarkupSymbols ||
        downloadingUserDataFields ||
        downloadingUserDataValues ||
        downloadingTasks ||
        downloadingTaskTypes
      );
    },
    nonEmptyFeatureLayerTableHeaders() {
      return this.featureTableHeaders.filter((h) => {
        return Boolean(h.text);
      });
    },
    filteredFeatureTableHeaders() {
      return this.featureTableHeaders.filter((h) => {
        return (
          this.featureLayerTableHeadersEnabled[h.value] || h.value === "menu"
        );
      });
    },
    featureTableHeaders() {
      const [feature] = this.selectedFeatureLayerFeatures ?? [];
      if (feature) {
        const fieldHeaders = Object.keys(feature)
          .filter(
            (f) =>
              !["object_id", "OBJECTID", "GlobalID", "mapServiceId"].includes(f)
          )
          .map((name) => {
            return {
              text: name,
              align: "start",
              value: name,
              minWidth: "150px",
              width: "180px",
            };
          });
        return [
          {
            text: "Feature ID",
            align: "start",
            value: "OBJECTID",
            minWidth: "150px",
            width: "150px",
          },
          {
            text: "Global ID",
            align: "start",
            value: "GlobalID",
            minWidth: "150px",
            width: "150px",
          },
          ...fieldHeaders,
          {
            text: "",
            align: "start",
            value: "menu",
            sortable: false,
          },
        ];
      }
      return [];
    },
    gisDataPoints() {
      if (this.selectedLayerType === "F") {
        return this.selectedFeatureLayerFeatures;
      } else if (this.selectedLayerType === "U") {
        return this.mappedGisDataPoints;
      }
      return [];
    },
    filteredSelectedFeatureLayerFeatures() {
      return this.selectedFeatureLayerFeatures.filter((g) => {
        if (this.filterChoices.length === 0) {
          return true;
        }

        const isMatch = this.filterChoices.every((fc) => {
          const { selectedField, selectedMatchChoice, selectedMatchValue } = fc;
          return checkMatch({
            val: g[selectedField],
            selectedMatchChoice,
            selectedMatchValue,
          });
        });
        return isMatch;
      });
    },
    selectedFeatureLayerFeatures() {
      const { selectedMapServiceId } = this;
      const { features = [], objectIdFieldName } = this.featureLayerQueryResult;
      return features.map((f) => {
        const mappedAttributesArr = Object.entries(f.attributes).map(
          ([key, val]) => {
            if (Array.isArray(this.getCodeValues(key))) {
              return [
                this.getEsriFieldAlias(key),
                this.getNameForCodeValue(key, val) ?? val,
              ];
            }
            return [this.getEsriFieldAlias(key), val];
          }
        );
        const mappedAttributes = Object.fromEntries(mappedAttributesArr);
        return {
          mapServiceId: selectedMapServiceId,
          OBJECTID: f.attributes[objectIdFieldName],
          ...mappedAttributes,
        };
      });
    },
    rawGisInfoAttributes() {
      if (!this.selectedGisInfo?.graphic?.attributes) {
        return {};
      }
      const { fields } = this.selectedGisInfo?.graphic?.layer ?? {};
      if (!Array.isArray(fields)) {
        if (
          this.selectedGisInfo.graphic?.sourceLayer?.layer
            ?.utiliSyncLayerType === "S"
        ) {
          return this.selectedGisInfo?.graphic?.attributes;
        } else {
          return {};
        }
      }

      const entries = fields.map(({ name: key }) => {
        const value = this.selectedGisInfo.graphic.attributes[key];
        return [key, value];
      });
      return Object.fromEntries(entries);
    },
    gisInfoAttributes() {
      if (!this.selectedGisInfo?.graphic?.attributes) {
        return {};
      }
      const { fields } = this.selectedGisInfo?.graphic?.layer ?? {};
      if (!Array.isArray(fields)) {
        if (
          this.selectedGisInfo.graphic?.sourceLayer?.layer
            ?.utiliSyncLayerType === "S"
        ) {
          return this.selectedGisInfo?.graphic?.attributes;
        } else {
          return {};
        }
      }

      const entries = fields.map(({ name: key }) => {
        const value = this.selectedGisInfo.graphic.attributes[key];
        return [this.findAliasByName(fields, key), value];
      });
      return Object.fromEntries(entries);
    },
    selectedGisInfoId() {
      return getGisInfoGlobalId(this.selectedGisInfoObj);
    },
    selectedFeatureId() {
      return +getGisInfoObjectId(this.selectedGisInfoObj);
    },
    selectedGisInfoObj() {
      return this.gisInfos?.[this.selectedGisInfoIndex];
    },
    selectedLayerFields() {
      return this.selectedLayer?.fields;
    },
    selectedLayerType() {
      return this.selectedLayer?.service_type;
    },
    selectedLayers() {
      const { selectedLayer } = this;
      if (selectedLayer) {
        return [selectedLayer];
      }
      return [];
    },
    selectedLayer() {
      const selectedLayer = this.layers?.find((l) => {
        return this.selectedMapServiceId === l.map_service_id;
      });
      return { ...selectedLayer };
    },
    layerChoices() {
      return this.layers
        .filter((l) => {
          if (!l.is_active) {
            return false;
          }

          if (this.isOnline) {
            return ["F", "U"].includes(l.service_type);
          } else {
            return ["U"].includes(l.service_type);
          }
        })
        .map((l) => {
          const { service_name: label, map_service_id: value } = l;
          return { label, value };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    mappedGisDataPoints() {
      return this.gisDataPointValues;
    },
    filteredMappedGisDataPoints() {
      return this.mappedGisDataPoints.filter((g) => {
        if (this.filterChoices.length === 0) {
          return true;
        }

        const isMatch = this.filterChoices.every((fc) => {
          const { selectedField, selectedMatchChoice, selectedMatchValue } = fc;
          return checkMatch({
            val: g[selectedField],
            selectedMatchChoice,
            selectedMatchValue,
          });
        });
        return isMatch;
      });
    },
    headers() {
      const [value = {}] = this.gisDataPointValues;
      const fieldHeaders = Object.keys(value)
        .filter(
          (v) =>
            ![
              "geo_json",
              "object_id",
              "gis_data_point_id",
              "map_service_id",
            ].includes(v)
        )
        .map((name) => {
          return {
            text: name,
            align: "start",
            value: name,
            minWidth: "150px",
            width: "150px",
          };
        });
      return [
        {
          text: "Feature ID",
          align: "start",
          value: "object_id",
          sortable: false,
          minWidth: "120px",
          width: "120px",
        },
        {
          text: "Global ID",
          align: "start",
          value: "gis_data_point_id",
          sortable: false,
          minWidth: "120px",
          width: "120px",
        },
        ...fieldHeaders,
        {
          text: "",
          align: "start",
          value: "menu",
          sortable: false,
        },
      ];
    },
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value] || h.value === "menu";
      });
    },
  },
  data() {
    return {
      layers: [],
      gisDataPointValues: [],
      selectedMapServiceId: undefined,
      showNotificationPane: false,
      mdiClose,
      mdiFilter,
      mdiPlusCircle,
      mdiRefresh,
      mdiInformation,
      mdiCloudOffOutline,
      gisInfoId: undefined,
      featureId: undefined,
      showGisInfoPanel: false,
      selectedGisInfo: {},
      gisInfoObjectId: undefined,
      gisInfoTab: undefined,
      showTicketLogTab: false,
      showTasksTab: false,
      showDocsTab: false,
      showLoader: false,
      showGisDataPointFilterDialog: false,
      headersEnabled: {},
      filterChoices: [],
      map: undefined,
      view: undefined,
      gisInfos: [],
      selectedGisInfoIndex: 0,
      featureTable: undefined,
      showExpansionPanelDialog: false,
      featureLayerQueryResult: {},
      featureLayerTableHeadersEnabled: {},
      showFeatureLayerGisInfoPanel: false,
      selectedFeatureLayerGlobalId: undefined,
      selectedFeatureLayerObjectId: undefined,
      featureItemGisInfoAttributes: {},
      attributes: {},
      featureItemFields: [],
      selectedRowValue: {},
      lastUpdated: new Date(),
      tableHeight: 0,
      taskCount: undefined,
      objectId: undefined,
      showFormSubmittedSnackbar: false,
      showEditFormDialog: false,
      formResultBeingViewed: {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      },
      showOfflinePane: false,
      highlightedRowIds: [],
    };
  },
  watch: {
    dynamicFormProps: {
      deep: true,
      async handler(val) {
        this.showEditFormDialog = false;
        await this.$nextTick();
        if (
          val?.formDefinition?.form &&
          val?.globalId &&
          val?.objectId &&
          val?.selectedMapServiceId
        ) {
          this.showEditFormDialog = true;
        }
      },
    },
    formSubmitted: {
      deep: true,
      handler({ isFormSubmitted }) {
        if (isFormSubmitted) {
          this.showFormSubmittedSnackbar = true;
        }
      },
    },
    async selectedMapServiceId(val) {
      this.showGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
      if (!navigator.onLine) {
        return;
      }
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === val)
        .toArray();
      const mapServiceUrl = mapService?.service_url;
      if (mapServiceUrl) {
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${mapServiceUrl}/query`,
          {
            params: {
              where: "1=1",
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          }
        );
        this.featureItemFields = queryResult?.fields ?? [];
      }
    },
    gisInfoTab(val) {
      this.showTicketLogTab = false;
      this.showTasksTab = false;
      this.showDocsTab = false;
      if (val === TABS.TASKS_TAB) {
        this.showTasksTab = true;
      } else if (val === TABS.TICKET_LOG_TAB) {
        this.showTicketLogTab = true;
      } else if (val === TABS.DOCS_TAB) {
        this.showDocsTab = true;
      }
    },
    nonEmptyHeaders: {
      deep: true,
      handler(val) {
        for (const h of val) {
          if (!["object_id", "gis_data_point_id"].includes(h.value)) {
            this.$set(this.headersEnabled, h.value, true);
          }
        }
      },
    },
    nonEmptyFeatureLayerTableHeaders: {
      deep: true,
      handler(val) {
        for (const h of val) {
          if (!["object_id", "OBJECTID", "GlobalID"].includes(h.value)) {
            this.$set(this.featureLayerTableHeadersEnabled, h.value, true);
          }
        }
      },
    },
    selectedGisInfoIndex: {
      immediate: true,
      async handler(val) {
        const gisInfo = this.gisInfos?.[val];
        if (!gisInfo) {
          return;
        }
        await this.getGisInfoData(gisInfo);
      },
    },
    gisInfos: {
      deep: true,
      immediate: true,
      async handler(val) {
        const gisInfo = val?.[this.selectedGisInfoIndex];
        if (!gisInfo) {
          return;
        }
        await this.getGisInfoData(gisInfo);
      },
    },
    layerChoices: {
      deep: true,
      handler(val) {
        const selectedMapServiceId = localStorage.getItem(
          "selected-map-service-id"
        );
        if (selectedMapServiceId) {
          this.selectedMapServiceId = selectedMapServiceId;
        } else {
          const [layer] = val;
          this.selectedMapServiceId = layer?.value;
        }
      },
    },
    map: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (val) {
          await this.$nextTick();
          await this.onLayerIdChange();
        }
      },
    },
    layers: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          await this.$nextTick();
          await this.onLayerIdChange();
        }
      },
    },
    "$vuetify.breakpoint.xsOnly"(currVal, oldVal) {
      if (currVal !== oldVal) {
        this.showFeatureLayerGisInfoPanel = false;
        this.showGisInfoPanel = false;
        this.showTicketLogTab = false;
        this.showTasksTab = false;
        this.showDocsTab = false;
      }
    },
  },
  methods: {
    ...mapMutations(["setFormSubmitted", "setDynamicFormProps"]),
    getEsriFieldAlias(key) {
      const field = [...this.featureItemFields].find(
        (f) =>
          f.name?.replaceAll(" ", "")?.toLowerCase() ===
          key.replaceAll(" ", "")?.toLowerCase()
      );
      return field?.alias ?? key;
    },
    getRowClass(item) {
      const { selectedMapServiceId } = this;
      const selectedLayer = this.map?.layers?.items?.find((l) => {
        return l.mapServiceId === selectedMapServiceId;
      });

      if (selectedLayer?.utiliSyncLayerType === "U") {
        const { object_id: featureId } = item;
        if (this.highlightedRowIds.includes(featureId)) {
          return "highlighted-row";
        }
      } else if (selectedLayer?.utiliSyncLayerType === "F") {
        const field = selectedLayer?.fields?.find((f) => f.type === "oid");
        const objectIdFieldName = field?.name;
        const { OBJECTID, [objectIdFieldName]: objectId } = item;
        if (
          this.highlightedRowIds.includes(OBJECTID) ||
          this.highlightedRowIds.includes(objectId)
        ) {
          return "highlighted-row";
        }
      }
    },
    async getActiveTasks() {
      const { objectId, selectedMapServiceId } = this;
      if (!objectId || !selectedMapServiceId || !navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/action_item_and_tasks`, {
        params: {
          map_service_id: selectedMapServiceId,
          object_id: objectId,
        },
      });
      this.tasks = results;
      const filteredTasks = this.tasks.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled"
      );
      this.taskCount = filteredTasks.length;
    },
    getCodeValues(gisInfoAttributeKey) {
      return this.featureItemFields?.find(
        (f) => f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
      )?.domain?.codedValues;
    },
    getNameForCodeValue(gisInfoAttributeKey, value) {
      return this.featureItemFields
        ?.find(
          (f) =>
            f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
        )
        ?.domain?.codedValues?.find?.((c) => c.code === value)?.name;
    },
    async viewForm() {
      const { formResultId } = this.formSubmitted;
      const { data: formResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      const { feature_id: objectId, map_service_id: mapServiceId } = formResult;
      const { gisDataPointId } = formResult.feature_attributes;
      this.showEditFormDialog = true;
      this.setFormSubmitted({
        isFormSubmitted: false,
        formResultId: undefined,
      });
      const dynamicFormProps = {
        formDefinition: formResult,
        existingFormResultIdMap: {
          formResultId,
        },
        selectedPdfFileUrl: formResult.pdfFileUrl,
        globalId: gisDataPointId,
        objectId,
        selectedMapServiceId: mapServiceId,
        canEdit: formResult?.form?.status !== "SUBMITTED_FINAL",
      };
      this.setDynamicFormProps(dynamicFormProps);
    },
    getEsriFieldType(esriFieldKey) {
      const field = this.featureLayerQueryResult.fields?.find(
        (f) => f.name === esriFieldKey || f.alias === esriFieldKey
      );
      return field?.type;
    },
    onResize() {
      const height =
        window.innerHeight -
        (this.$refs.filterDisplay?.clientHeight ?? 100) -
        (this.$refs.bottomBar?.clientHeight ?? 0) -
        (this.$refs.topBar?.$el?.clientHeight ?? 56) -
        25;
      this.tableHeight = Math.max(height, 200);
    },
    async reload() {
      await this.downloadAuthObject();
      await this.downloadData(true);
    },
    isRichTextField(inputValue) {
      return Boolean(inputValue?.match?.(/<[^>]*>/g));
    },
    async reloadRowClicked() {
      await this.$nextTick();
      this.loadData(this.selectedRowValue);
    },
    removeFilter(index) {
      this.filterChoices.splice(index, 1);
    },
    onUpdateFilter(choices) {
      this.showGisDataPointFilterDialog = false;
      this.filterChoices = cloneDeep(choices);
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    onGisInfoPanelClose() {
      this.showGisInfoPanel = false;
      this.gisInfos = [];
      this.featureTable?.clearSelection();
      this.highlightedRowIds = [];
    },
    async onMapCreated({ map, view }) {
      await this.$nextTick();
      this.map = map;
      this.view = view;
    },
    highlightRow(value) {
      const { selectedMapServiceId } = this;
      const selectedLayer = this.map?.layers?.items?.find((l) => {
        return l.mapServiceId === selectedMapServiceId;
      });

      if (selectedLayer?.utiliSyncLayerType === "U") {
        const { object_id: featureId } = value;
        this.highlightedRowIds = [featureId];
      } else if (selectedLayer?.utiliSyncLayerType === "F") {
        const field = selectedLayer?.fields?.find((f) => f.type === "oid");
        const objectIdFieldName = field?.name;
        const { OBJECTID, [objectIdFieldName]: objectId } = value;
        this.highlightedRowIds = [objectId ?? OBJECTID];
      }
    },
    async loadData(value) {
      this.selectedRowValue = value;
      const { selectedMapServiceId } = this;
      const selectedLayer = this.map?.layers?.items?.find((l) => {
        return l.mapServiceId === selectedMapServiceId;
      });

      if (selectedLayer?.utiliSyncLayerType === "U") {
        this.selectedGisInfo = value;
        const { object_id: featureId, gis_data_point_id: globalId } = value;
        this.gisInfoId = globalId;
        this.featureId = featureId;
        const items = selectedLayer?.graphics._items;
        const item = items.find((it) => {
          return it?.attributes?.OBJECTID === featureId;
        });
        this.gisInfos = [item];
        this.selectedGisInfoIndex = 0;
        this.showGisInfoPanel = true;
        this.objectId = featureId;
        await this.createSharedSiteIfNotExist();
      } else if (selectedLayer?.utiliSyncLayerType === "F") {
        if (!navigator.onLine) {
          this.showOfflinePane = true;
          return;
        }
        const field = selectedLayer?.fields?.find((f) => f.type === "oid");
        const objectIdFieldName = field?.name;
        const { OBJECTID, [objectIdFieldName]: objectId } = value;
        const [mapService] = await db.mapServices
          .filter((m) => m.map_service_id === selectedMapServiceId)
          .toArray();
        const mapServiceUrl = mapService?.service_url;
        let queryResult = {};
        try {
          const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
            params: {
              objectids: OBJECTID ?? objectId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          });
          queryResult = data;
        } finally {
          const { features, globalIdFieldName, fields } = queryResult;
          const [{ attributes = {} }] = features ?? [{}];
          const [feature] = queryResult?.features;
          this.attributes = attributes;
          this.featureItemGisInfoAttributes = attributes ?? {};
          this.selectedFeatureLayerObjectId =
            +attributes[objectIdFieldName] ?? OBJECTID ?? objectId;
          this.selectedFeatureLayerGlobalId = attributes[globalIdFieldName];
          this.showFeatureLayerGisInfoPanel = true;
          this.objectId = OBJECTID ?? objectId;
          this.gisInfoId = feature?.attributes?.[globalIdFieldName];
          await this.createSharedSiteIfNotExist();
          this.featureItemFields = fields;
        }
      }
    },
    async onRowClick(value) {
      this.showGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
      await this.loadData(value);
      this.highlightRow(value);
    },
    async getAllLayers() {
      this.showLoader = true;
      const collection = await db.mapServices.orderBy("service_name");
      this.layers = await collection.toArray();
      const [firstLayer] = this.layers;
      this.selectedMapServiceId = firstLayer?.map_service_id;
      this.showLoader = false;
    },
    async onLayerIdChange() {
      if (!this.selectedMapServiceId) {
        return;
      }
      localStorage.setItem(
        "selected-map-service-id",
        this.selectedMapServiceId
      );

      const [watchUtils] = await loadModules(["esri/core/watchUtils"]);
      await Promise.all(
        this.map?.layers?.items?.map((lv) => {
          return Promise.race([
            watchUtils.whenEqualOnce(lv, "loadStatus", "loaded"),
            watchUtils.whenEqualOnce(lv, "loadStatus", "failed"),
          ]);
        }) ?? []
      );
      this.showLoader = true;
      const gisDataFields = await db.gisDataFields.toCollection().toArray();
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const gisDataPoints = await db.gisDataPoints
        .filter((m) => {
          return m.map_service_id === this.selectedMapServiceId;
        })
        .toArray();
      this.gisDataPointValues = gisDataPoints.map((g) => {
        const { object_id: objectId } = g;
        const entries = gisDataFields
          .filter((g) => {
            return g.map_service_id === this.selectedMapServiceId;
          })
          .map((f) => {
            const { name, gis_data_field_id: gisDataFieldId } = f;
            const value = gisDataValues.find((gv) => {
              return (
                gv.gis_data_field_id === gisDataFieldId &&
                gv.feature_id === objectId
              );
            })?.value;
            return [name, value];
          });

        const valueObj = Object.fromEntries(entries);
        return { ...g, ...valueObj };
      });

      if (this.selectedLayerType === "F") {
        const { service_url: mapServiceUrl } = this.selectedLayer;
        if (!mapServiceUrl) {
          return;
        }
        const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
          params: {
            where: "1=1",
            outFields: "*",
            f: "json",
            returnGeometry: false,
            token: localStorage.getItem("esri_token"),
          },
        });
        this.featureLayerQueryResult = data;
      }
      this.showLoader = false;
    },
    async onTicketEditFormSubmitted() {
      await this.$nextTick();
    },
    async reloadData() {
      if (!navigator.onLine) {
        await this.getAllLayers();
        return;
      }

      try {
        if (localStorage.getItem("bulk-download-complete") !== "true") {
          await this.bulkDownloadData();
        }
        await this.getAllLayers();
        localStorage.setItem("bulk-download-complete", true);
        const lastFullDownloadCompleted = localStorage.getItem(
          "last-full-download-completed"
        );
        const fullDownload =
          !lastFullDownloadCompleted ||
          (lastFullDownloadCompleted &&
            moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
        await this.downloadData(!fullDownload);
      } catch (error) {
        if (Object.values(Dexie.errnames).includes(error?.name)) {
          this.signOut();
        }
      } finally {
        await this.getAllLayers();
      }
    },
    async onOnline() {
      localStorage.removeItem("bulk-download-complete");
      localStorage.removeItem("last-full-download-completed");
      await this.reloadData();
    },
  },
  async beforeMount() {
    this.reloadData();
    this.checkEsriTokenExpirationPeriodically();
  },
  async mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    window.addEventListener("resize", this.onResize);
    window.addEventListener("online", this.reloadData);
    await this.$nextTick();
    this.onResize();

    const formResultId = sessionStorage.getItem("formResultId");
    if (formResultId) {
      await this.getFormResult(formResultId);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("online", this.reloadData);
  },
};
</script>

<style>
td * {
  margin: 0 !important;
}

.highlighted-row {
  background-color: rgb(1, 157, 178, 0.3) !important;
}
</style>
