import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import { mapGetters } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      showAfterFormSubmissionUtilibotDialog: false,
    };
  },
  computed: {
    ...mapGetters(["formSubmitted"]),
  },
  methods: {
    async showUtiliBotDialogOnFormSubmitted() {
      const { formResultId } = this.formSubmitted;
      const { data: formResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      const { form_definition_id: formDefinitionId } = formResult;
      const [formDefinition] = await db.formDefinitions
        .filter((f) => f.form_definition_id === formDefinitionId)
        .toArray();
      const showUtiliBotsOnFormSubmission = Boolean(
        formDefinition?.form?.formDescription?.showUtiliBotsOnFormSubmission
      );
      this.showAfterFormSubmissionUtilibotDialog =
        showUtiliBotsOnFormSubmission;
    },
  },
};
